var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "airgsm-container"
  }, [_c('div', {
    staticClass: "tw-overflow-x-auto tw-py-9 tw-px-5 sm:tw-px-7.5"
  }, [_c('div', {
    class: ['tw-mx-auto', 'tw-grid', _vm.classes.cols, 'tw-gap-4', _vm.classes.width]
  }, [_vm.hasInvitation ? _c('Invitation', {
    attrs: {
      "organization": _vm.organization
    }
  }) : _vm._e(), _vm._v(" "), _vm.hasFreemium ? _c('Freemium') : _vm._e(), _vm._v(" "), _vm.hasReferral ? _c('Referral') : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }