<template>
  <div class="airgsm-container">
    <div class="tw-overflow-x-auto tw-py-9 tw-px-5 sm:tw-px-7.5">
      <div :class="['tw-mx-auto', 'tw-grid', classes.cols, 'tw-gap-4', classes.width]">
        <Invitation v-if="hasInvitation" :organization="organization" />
        <Freemium v-if="hasFreemium" />
        <Referral v-if="hasReferral" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  export default {
    name: 'homepage_banners',
    components: {
      Invitation: () => import('~/components/banners/invitation.vue'),
      Referral: () => import('~/components/banners/referral.vue'),
      Freemium: () => import('~/components/freemium/banner.vue'),
    },
    data() {
      return {
        organization: '',
        hasInvitation: false,
        hasReferral: false,
        hasFreemium: false,
      }
    },
    watch: {
      isAuthenticated() {
        this.init()
      },
    },
    computed: {
      ...mapState({
        user: (state) => state.auth.user,
      }),
      ...mapGetters({
        isAuthenticated: 'is_authenticated',
        companyInvitations: 'userSettings/get_company_invitations',
      }),
      classes() {
        const cols = ['tw-grid-cols-1', 'tw-grid-cols-1', 'tw-grid-cols-2', 'tw-grid-cols-3']
        const widths = ['tw-max-w-[620px]', 'tw-max-w-[620px]', 'tw-w-[620px]', 'tw-w-[934px]']
        const length = [this.hasInvitation, this.hasFreemium, this.hasReferral].reduce((prev, curr) => {
          return prev + Number(curr)
        }, 0)

        return {
          cols: cols[length],
          width: widths[length],
        }
      },
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        const isFreemiumFFActive = Boolean(this.$featureFlag?.isActive('freemium'))

        if (this.isAuthenticated) {
          /**
           * Has invitation
           */
          if (this.companyInvitations?.length > 0) {
            this.hasInvitation = true
            this.organization = this.companyInvitations.join(', ')
          }

          /**
           * Show referral banner
           */
          if (this.$featureFlag?.isActive('web_referral_banner_show')) {
            this.hasReferral = Boolean(this.user?.referral?.show_banner)
          }

          /**
           * Show freemium banner
           */
          this.hasFreemium = isFreemiumFFActive && Boolean(this.user?.is_freemium_claimable)
        } else {
          this.organization = ''
          this.hasInvitation = false
          this.hasReferral = false
          this.hasFreemium = isFreemiumFFActive
        }
      },
    },
  }
</script>
